@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  margin-top: 15px;
}
.weapon-image{
  width: 160px;
  height: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
}
.course-description {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 32px 32px;
}
.course-description h3 {
  color: #fff;
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
}
.course-description .price {
  margin-top: 24px;
}
.course-description .check-training {
  margin-top: 24px;
}


.img-cover img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}